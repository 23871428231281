.proba {
  background-image: url("./images/background_slider.png"); }
  .proba:hover .slider {
    display: none; }
  .proba .slider {
    position: absolute;
    width: 100%;
    height: 8px;
    overflow-x: hidden; }
  .proba .line {
    position: absolute;
    width: 100%;
    height: 20px; }
  .proba .subline {
    background: #1D538C;
    height: 8px; }
  .proba .inc {
    animation: increase 5s infinite; }

@keyframes increase {
  from {
    left: 0%;
    width: 0%; }
  to {
    left: 0%;
    width: 97%; } }
  .proba .wrapper .carousel-indicators {
    right: unset;
    left: 37px;
    bottom: unset;
    top: 408px;
    padding-left: unset;
    margin-left: unset;
    display: block; }
    .proba .wrapper .carousel-indicators li {
      width: 50px;
      height: 14px;
      cursor: pointer;
      background-color: #333333; }
  .proba .wrapper .carousel-indicators .active {
    background-color: #333333;
    width: 50px;
    height: 14px; }
  .proba .wrapper .carousel {
    width: 100%; }
    .proba .wrapper .carousel .carousel-inner .carousel-item .container {
      position: relative;
      padding-top: 50px;
      text-align: center; }
      .proba .wrapper .carousel .carousel-inner .carousel-item .container h6 {
        font-size: 24px;
        line-height: 24px;
        font-family: 'Barlow', sans-serif;
        color: #333333; }
      .proba .wrapper .carousel .carousel-inner .carousel-item .container h2 {
        font-size: 48px;
        line-height: 48px;
        font-family: 'Barlow', sans-serif;
        font-weight: 800;
        color: #333333; }
    .proba .wrapper .carousel .arrows {
      position: absolute;
      display: block;
      top: 290px;
      right: 20px; }
      .proba .wrapper .carousel .arrows a {
        height: 35px;
        width: 35px;
        color: #333333; }
      .proba .wrapper .carousel .arrows .carousel-control-prev {
        left: unset;
        opacity: unset;
        right: 40px;
        top: 30px; }
        .proba .wrapper .carousel .arrows .carousel-control-prev .carousel-control-prev-icon {
          background-image: url("./images/SVG/left.svg");
          width: 32px;
          height: 25px; }
      .proba .wrapper .carousel .arrows .carousel-control-next {
        opacity: unset;
        right: 40px; }
        .proba .wrapper .carousel .arrows .carousel-control-next .carousel-control-next-icon {
          background-image: url("./images/SVG/right.svg");
          width: 32px;
          height: 25px; }
    .proba .wrapper .carousel .container {
      position: relative; }
      .proba .wrapper .carousel .container .left_slider {
        position: absolute;
        bottom: 52px;
        left: 0; }
        .proba .wrapper .carousel .container .left_slider h6 {
          font-family: 'Barlow', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px; }
        .proba .wrapper .carousel .container .left_slider svg {
          position: relative;
          bottom: 7px;
          right: 10px; }
      .proba .wrapper .carousel .container .right_slider {
        display: flex;
        position: absolute;
        bottom: 52px;
        right: 0; }
        .proba .wrapper .carousel .container .right_slider h6 {
          font-family: 'Barlow', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-break: 22px; }
        .proba .wrapper .carousel .container .right_slider svg {
          margin-left: 20px;
          position: relative;
          bottom: 7px; }
  .proba .wrapper .caption1 {
    text-align: start;
    position: absolute;
    top: 100px;
    right: 0; }
    .proba .wrapper .caption1 .btn {
      margin-top: 49px;
      text-transform: uppercase;
      float: right;
      color: white;
      width: 160px;
      height: 54px;
      border-radius: 204px;
      background-color: #333333; }
  .proba .wrapper .left_side {
    display: block;
    position: absolute;
    left: 0;
    top: 100.6px;
    text-align: start; }
    .proba .wrapper .left_side p {
      font-size: 16px;
      font-family: 'Barlow', sans-serif;
      font-weight: 500;
      line-height: 24px; }
  .proba .wrapper .description {
    position: absolute;
    top: 300px; }
    .proba .wrapper .description p {
      font-size: 16px;
      font-family: 'Barlow', sans-serif;
      font-weight: 500;
      line-height: 24px;
      width: 165px;
      text-align: start; }
  .proba .wrapper .description2 {
    position: absolute;
    left: 0;
    top: 300px; }
    .proba .wrapper .description2 p {
      font-size: 16px;
      font-family: 'Barlow', sans-serif;
      font-weight: 500;
      line-height: 24px;
      width: 174px;
      text-align: start; }
  .proba .wrapper .description3 {
    position: absolute;
    left: 0;
    top: 420px; }
    .proba .wrapper .description3 p {
      font-size: 16px;
      font-family: 'Barlow', sans-serif;
      font-weight: 500;
      line-height: 24px;
      text-align: start;
      width: 170px; }
  .proba .wrapper .caption2 {
    text-align: start;
    position: absolute;
    top: 100px;
    right: 0; }
    .proba .wrapper .caption2 .btn {
      margin-top: 49px;
      text-transform: uppercase;
      float: left;
      color: white;
      width: 160px;
      height: 54px;
      border-radius: 204px;
      background-color: #333333; }
  .proba .wrapper .caption3 {
    text-align: start;
    position: absolute;
    top: 70px;
    left: 0; }
  .proba .right_side {
    position: absolute;
    right: 0;
    top: 70px;
    display: block; }
  .proba .plus {
    position: absolute;
    display: block;
    top: 290px;
    left: 174px; }
  .proba .plus2 {
    position: absolute;
    display: block;
    top: 302px;
    left: 270px; }
  .proba .plus3 {
    position: absolute;
    display: block;
    top: 409px;
    left: 207px; }

/*------------*/
.middle {
  position: relative;
  top: -70px;
  text-align: center; }
  .middle .btn {
    background: url("./images/SVG/middleSlider.svg") center;
    background-size: contain;
    width: 71px;
    height: 71px;
    border-radius: 100%;
    border: none; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .proba .slider {
    display: none; }
  .proba .wrapper .carousel .caption1 {
    padding-top: unset;
    top: 0;
    right: 10px; }
    .proba .wrapper .carousel .caption1 h6 {
      margin: 0; }
  .proba .wrapper .carousel .plus {
    display: none; }
  .proba .wrapper .carousel .left_side {
    display: none; }
  .proba .wrapper .carousel .description {
    display: none; }
  .proba .wrapper .carousel .caption2 {
    text-align: unset;
    right: 20px;
    top: 0; }
    .proba .wrapper .carousel .caption2 h6 {
      margin: 0; }
  .proba .wrapper .carousel .plus2 {
    display: none; }
  .proba .wrapper .carousel .description2 {
    display: none; }
  .proba .wrapper .carousel .caption3 {
    top: 0;
    text-align: unset;
    left: 39px; }
  .proba .wrapper .carousel .plus3 {
    display: none; }
  .proba .wrapper .carousel .right_side {
    display: none; }
  .proba .wrapper .carousel .description3 {
    display: none; }
  .proba .wrapper .carousel .arrows {
    display: none; }
  .proba .wrapper .carousel-indicators {
    left: 7px;
    top: 177px; }
    .proba .wrapper .carousel-indicators li {
      width: 30px;
      height: 10px; }
    .proba .wrapper .carousel-indicators .active {
      width: 30px;
      height: 10px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container {
    padding: unset; }
    .proba .wrapper .carousel .carousel-inner .carousel-item .container h6 {
      font-size: 16px;
      line-height: 24px; }
    .proba .wrapper .carousel .carousel-inner .carousel-item .container h2 {
      font-size: 18px;
      line-height: 18px; }
    .proba .wrapper .carousel .carousel-inner .carousel-item .container .btn {
      margin-top: 0;
      width: 80px;
      height: 25px;
      font-size: 10px;
      padding: 0 !important; }
  .proba .wrapper .carousel .container .left_slider {
    display: none; }
  .proba .wrapper .carousel .container .right_slider {
    display: none; }
  .proba .slika {
    top: 10px;
    height: 340px; }
  .middle {
    position: relative;
    top: -57px; }
    .middle .btn {
      width: 51px;
      height: 51px; } }

/*tablet1*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .proba .slider {
    display: none; }
  .proba .wrapper .carousel .caption1 {
    right: 50px; }
  .proba .wrapper .carousel .plus {
    top: 270px;
    left: 120px; }
  .proba .wrapper .carousel .left_side {
    display: none; }
  .proba .wrapper .carousel .description p {
    width: 100px; }
  .proba .wrapper .carousel .caption2 {
    right: 50px; }
  .proba .wrapper .carousel .plus2 {
    top: 190px;
    left: 140px; }
  .proba .wrapper .carousel .description2 {
    top: 240px;
    left: 40px; }
    .proba .wrapper .carousel .description2 p {
      width: 100px; }
  .proba .wrapper .carousel .caption3 {
    top: 80px;
    left: 70px; }
  .proba .wrapper .carousel .plus3 {
    top: 271px;
    left: 104px; }
  .proba .wrapper .carousel .right_side {
    display: none; }
  .proba .wrapper .carousel .description3 {
    left: 14px;
    top: 328px; }
    .proba .wrapper .carousel .description3 p {
      width: 100px; }
  .proba .wrapper .carousel .arrows {
    display: none; }
  .proba .wrapper .carousel-indicators {
    left: 37px;
    top: 250px; }
    .proba .wrapper .carousel-indicators li {
      width: 30px;
      height: 10px; }
    .proba .wrapper .carousel-indicators .active {
      width: 30px;
      height: 10px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container h6 {
    font-size: 30px;
    line-height: 30px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container h2 {
    font-size: 25px;
    line-height: 25px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container a .btn {
    margin-top: 15px;
    width: 130px;
    height: 40px; }
  .proba .wrapper .carousel .container .left_slider {
    bottom: 0;
    left: 30px; }
  .proba .wrapper .carousel .container .right_slider {
    right: 30px;
    bottom: 0; }
  .middle {
    position: relative; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .proba .slider {
    display: none; }
  .proba .wrapper .carousel .plus {
    top: 320px;
    left: 120px; }
  .proba .wrapper .carousel .left_side {
    display: none; }
  .proba .wrapper .carousel .description {
    top: 379px;
    left: 73px; }
  .proba .wrapper .carousel .caption2 {
    top: 120px;
    right: 123px; }
  .proba .wrapper .carousel .plus2 {
    top: 274px;
    left: 189px; }
  .proba .wrapper .carousel .description2 {
    top: 327px;
    left: 83px; }
  .proba .wrapper .carousel .caption3 {
    top: 106px;
    left: 103px; }
  .proba .wrapper .carousel .plus3 {
    top: 371px;
    left: 149px; }
  .proba .wrapper .carousel .right_side {
    display: none; }
  .proba .wrapper .carousel .description3 {
    left: 51px;
    top: 414px; }
  .proba .wrapper .carousel .arrows {
    display: block;
    right: 10px; }
  .proba .wrapper .carousel-indicators {
    left: 37px;
    top: 280px; }
    .proba .wrapper .carousel-indicators li {
      width: 50px;
      height: 14px; }
    .proba .wrapper .carousel-indicators .active {
      width: 50px;
      height: 14px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container h6 {
    font-size: 24px;
    line-height: 30px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container h2 {
    font-size: 48px;
    line-height: 48px; }
  .proba .wrapper .carousel .carousel-inner .carousel-item .container a .btn {
    margin-top: 15px;
    width: 160px;
    height: 54px;
    font-size: 18px; }
  .proba .wrapper .carousel .container .left_slider {
    bottom: 22px; }
  .proba .wrapper .carousel .container .right_slider {
    bottom: 22px; } }

.section-2 {
  padding-bottom: 78px;
  position: relative;
  margin-top: 90px;
  background-color: #F6F6F6;
  text-align: center; }
  .section-2 h4 {
    padding-top: 110px; }
    .section-2 h4::after {
      position: relative;
      left: 47.3%;
      content: '';
      display: block;
      height: 4px;
      width: 50px;
      background: #1D538C;
      margin-bottom: 56px;
      top: 14px; }
    .section-2 h4::before {
      content: url("./images/SVG/patike.svg");
      position: absolute;
      left: 48.5%;
      top: 60px; }
  .section-2 .box {
    max-width: 350px;
    max-height: 340px;
    background-color: #FFFFFF;
    font-family: 'Barlow', sans-serif; }
    .section-2 .box img {
      padding-top: 42px;
      padding-bottom: 29px; }
    .section-2 .box h2 {
      font-size: 28px;
      line-height: 24px;
      font-weight: 600;
      padding-bottom: 17px; }
    .section-2 .box p {
      font-size: 20px;
      line-height: 28px;
      padding: 0 67px 40px 67px; }
  .section-2 .color {
    background-color: #1D538C;
    color: #FFFFFF; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .section-2 {
    margin-top: 60px;
    padding-bottom: 25px; }
    .section-2 h4 {
      padding-top: 70px;
      font-size: 26px;
      line-height: 38px; }
      .section-2 h4::before {
        left: 42.5%;
        top: 30px; }
      .section-2 h4::after {
        left: 41.3%; }
    .section-2 .box h2 {
      font-size: 26px; }
    .section-2 .box p {
      font-size: 18px;
      line-height: 27px;
      padding: 0 40px 40px 40px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .section-2 {
    padding-bottom: 0; }
    .section-2 .box {
      margin-bottom: 30px;
      max-width: 100%; }
      .section-2 .box h2 {
        line-height: 20px; }
      .section-2 .box p {
        font-size: 18px;
        line-height: 25px; }
    .section-2 .last p {
      padding: 0 80px 30px 80px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .section-2 {
    margin-top: 40px; }
    .section-2 .box h2 {
      margin: 0; }
    .section-2 .box p {
      padding: 0 67px 40px 67px; } }

.navbar .navbar-brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -12px; }

.navbar .navbar-toggler {
  position: relative;
  top: -25px;
  right: 10px; }
  .navbar .navbar-toggler .fa-bars {
    color: #333333 !important;
    font-size: 34px !important;
    padding: 4px !important; }

.navbar .nav-item {
  margin-right: 70px;
  padding-top: 13px; }
  .navbar .nav-item .nav-link {
    color: #333333;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Barlow', sans-serif;
    font-weight: 600; }

.navbar .navbar-nav {
  position: relative; }

.navbar .ml-auto {
  position: relative; }

.dropdown {
  z-index: 4545456456;
  position: absolute !important;
  right: 30px;
  top: 30px; }
  .dropdown .btn {
    color: #333333;
    background-color: transparent !important;
    border: 1px solid #333333;
    border-radius: 0;
    width: 60px;
    height: 30px;
    font-size: 16px;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    padding-bottom: 5px;
    padding: unset;
    margin-top: unset; }
  .dropdown .dropdown-menu {
    min-width: unset;
    background-color: transparent !important;
    border: 1px solid #333333; }
    .dropdown .dropdown-menu .dropdown-item {
      color: #333333;
      font-family: 'Barlow', sans-serif;
      font-weight: 600; }

.show .btn {
  color: #333333 !important; }

.activ {
  z-index: -1; }
  .activ::after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #FFF700;
    position: relative;
    left: 47%;
    top: 7px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .navbar {
    padding: 1em 0 !important; }
    .navbar .navbar-brand {
      transform: unset;
      position: relative;
      top: -27px;
      left: -9px; }
    .navbar .no-ma {
      padding-bottom: 30px; }
    .navbar .navbar-nav {
      text-align: start;
      position: relative;
      left: unset;
      margin-left: 30px; }
    .navbar .ml-auto {
      text-align: start;
      margin-left: 30px !important; }
    .navbar .navbar-collapse {
      position: absolute !important;
      top: 80px;
      background-color: white;
      width: 53%;
      right: 16px;
      z-index: 222222;
      -webkit-box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.41);
      box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.41); }
  .activ::after {
    top: -14px;
    left: -15px;
    margin-bottom: -14px; }
  .dropdown {
    position: absolute !important;
    right: 70px;
    top: 33px; }
    .dropdown .btn {
      height: 28px;
      width: 45px; }
    .dropdown .dropdown-item {
      padding: 0.01rem 1rem; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .navbar .navbar-brand {
    position: absolute;
    left: 110px;
    top: -11px; }
  .navbar .navbar-toggler {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1021; }
  .navbar .navbar-nav {
    text-align: start;
    position: relative;
    left: unset;
    margin-left: 40px; }
  .navbar .ml-auto {
    text-align: start;
    margin-left: 40px !important; }
  .navbar .navbar-collapse {
    position: absolute !important;
    top: 90px;
    background-color: white;
    width: 33%;
    right: 16px;
    z-index: 2222;
    -webkit-box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.41);
    box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.41); }
  .activ:after {
    left: -22px;
    top: -14px; }
  .dropdown {
    position: absolute !important;
    top: 30px;
    right: 90px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .navbar .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -11px; }
  .navbar .navbar-collapse {
    top: 0;
    left: 70px;
    background-color: transparent;
    box-shadow: none; }
  .navbar .nav-item {
    margin-right: 0; }
  .navbar .navbar-nav {
    display: -webkit-box; }
  .navbar .ml-auto {
    display: -webkit-box;
    margin: 0;
    left: -90px; }
  .activ {
    z-index: -1; }
    .activ::after {
      left: 47%;
      top: 7px; }
  .dropdown {
    top: 21px;
    right: 20px; } }

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .navbar .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -11px; }
  .navbar .navbar-collapse {
    top: 0;
    left: 70px;
    background-color: transparent;
    box-shadow: none; }
  .navbar .nav-item {
    margin-right: 0; }
  .navbar .navbar-nav {
    display: -webkit-box; }
  .navbar .ml-auto {
    display: -webkit-box;
    margin: 0;
    left: 200px; }
  .activ {
    z-index: -1; }
    .activ::after {
      left: 47%;
      top: 7px; }
  .dropdown {
    top: 21px;
    right: 20px; } }

.footer {
  position: relative;
  background-color: #333333; }
  .footer .container {
    border-bottom: 1px solid #FEF402; }
    .footer .container .logo {
      position: relative;
      left: -10px;
      top: -6px; }
    .footer .container h4 {
      padding-top: 50px;
      font-size: 16px;
      line-height: 20px;
      color: white;
      font-family: 'Barlow', sans-serif; }
    .footer .container h5 {
      font-size: 20px;
      line-height: 20px;
      font-family: 'Barlow', sans-serif;
      font-weight: 600;
      color: white;
      padding-bottom: 50px; }
    .footer .container h6 {
      color: white;
      font-size: 14px;
      line-height: 17px;
      font-family: 'Barlow', sans-serif;
      position: absolute;
      bottom: 12px;
      left: 0; }
      .footer .container h6 svg {
        margin-left: 20px; }
        .footer .container h6 svg path {
          fill: #FEF402; }
    .footer .container .contactData a {
      cursor: default; }
    .footer .container .nav-item {
      list-style-type: none;
      padding-bottom: 12px; }
      .footer .container .nav-item a {
        color: white;
        text-decoration: none;
        font-size: 18px; }
      .footer .container .nav-item .nav-link {
        padding-left: 0 !important;
        color: white;
        text-decoration: none; }
    .footer .container .kontakt {
      display: inline;
      padding-right: 25px; }

.copyright {
  background-color: #333333; }
  .copyright p {
    padding-top: 28px;
    padding-bottom: 31px;
    font-size: 16px;
    font-family: "Barlow Regular";
    color: white; }
    .copyright p span a {
      color: white; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .footer .container h4 {
    padding-top: 30px; }
  .footer .container h6 {
    position: unset;
    padding-top: 10px; }
  .footer .container h5 {
    padding-bottom: 30px;
    padding-top: 10px;
    font-size: 18px; }
  .footer .container .nav-item {
    padding-bottom: 10px; }
    .footer .container .nav-item a {
      font-size: 16px; }
  .copyright p {
    font-size: 16px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .footer .container h5 {
    padding-bottom: 12px;
    padding-top: 20px; }
  .footer .container h6 {
    padding: 10px 0 10px 0;
    position: unset; }
  .footer .container .nav-item a {
    font-size: 16px; }
  .footer .container .kontakt {
    padding-right: 5px; } }

.vjesti {
  padding-top: 70px; }
  .vjesti .no-gutter > [class*="col-"] {
    padding-left: 0; }
  .vjesti .imageNews {
    height: 150px;
    object-fit: cover; }
  .vjesti .text {
    padding-bottom: 20px; }
    .vjesti .text a {
      color: #222222;
      text-decoration: none; }
      .vjesti .text a:hover {
        text-decoration: none; }
    .vjesti .text h6 {
      font-size: 24px;
      line-height: 28px;
      font-family: 'Barlow', sans-serif;
      font-weight: 600;
      margin-bottom: 0; }
    .vjesti .text h5 {
      font-size: 16px;
      line-height: 19px;
      font-family: 'Barlow', sans-serif;
      color: #0064A1; }
    .vjesti .text p {
      font-size: 18px;
      line-height: 24px;
      font-family: 'Barlow', sans-serif;
      height: 72px;
      overflow: hidden; }

/*singleNews*/
.textSingle,
.singleHeader_component {
  margin-top: 80px; }
  .textSingle h6,
  .singleHeader_component h6 {
    font-size: 36px;
    line-height: 36px;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    padding-bottom: 4px; }
  .textSingle h5,
  .singleHeader_component h5 {
    font-size: 16px;
    line-height: 19px;
    font-family: 'Barlow', sans-serif;
    color: #0064A1;
    padding-bottom: 4px; }
  .textSingle p,
  .singleHeader_component p {
    padding-bottom: 4px; }

.singleN-wrapper .vjesti {
  padding-top: unset; }
  .singleN-wrapper .vjesti .no-gutter > [class*="col-"] {
    padding-right: 15px;
    padding-left: 0; }
  .singleN-wrapper .vjesti .imageNews {
    height: auto; }
  .singleN-wrapper .vjesti .text {
    padding-top: 10px; }
    .singleN-wrapper .vjesti .text h6 {
      margin-bottom: 8px; }

.singleHeader_component {
  margin-top: 155px; }

/*end*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .vjesti {
    padding-top: unset; }
    .vjesti .no-gutter > [class*="col-"] {
      padding-right: 15px;
      padding-left: 15px; }
    .vjesti .imageNews {
      height: auto; }
    .vjesti .text {
      padding-top: 10px; }
  /**/
  .singleHeader_component {
    margin-top: 0; }
  /**/ }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .vjesti {
    padding-top: 0; }
  /**/
  .singleHeader_component {
    margin-top: 0; }
  /**/ }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .vjesti {
    padding-top: 0; }
    .vjesti .no-gutter {
      all: unset; }
    .vjesti .imageNews {
      height: unset; }
    .vjesti .text h6 {
      padding-top: 8px;
      padding-bottom: 8px;
      padding: 8px 0 8px 0; }
  .singleHeader_component h6 {
    margin-left: 15px;
    padding-top: 20px; } }

.news_wrapper .vjesti {
  padding: 64px 0 70px 0; }
  .news_wrapper .vjesti .no-gutter {
    padding-left: 15px; }
    .news_wrapper .vjesti .no-gutter .imageNews {
      height: unset;
      padding-bottom: 20px; }
    .news_wrapper .vjesti .no-gutter .text {
      padding-top: 0;
      padding-bottom: unset; }
      .news_wrapper .vjesti .no-gutter .text h6 {
        padding-bottom: 8px; }
      .news_wrapper .vjesti .no-gutter .text h5 {
        padding-bottom: 7px; }
      .news_wrapper .vjesti .no-gutter .text p {
        height: 132px;
        margin: 0; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .news_wrapper .vjesti {
    padding: 0; }
    .news_wrapper .vjesti .no-gutter {
      padding-left: 0; }
      .news_wrapper .vjesti .no-gutter .imageNews {
        height: unset;
        padding-bottom: 20px; }
      .news_wrapper .vjesti .no-gutter .text {
        padding-top: 0; }
        .news_wrapper .vjesti .no-gutter .text h6 {
          padding-bottom: 8px; }
        .news_wrapper .vjesti .no-gutter .text h5 {
          padding-bottom: 7px; }
        .news_wrapper .vjesti .no-gutter .text p {
          height: 118px;
          margin: 0;
          font-size: 16px;
          margin-bottom: 32px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .news_wrapper {
    padding-top: 30px; }
    .news_wrapper .vjesti {
      padding: 0; }
      .news_wrapper .vjesti .no-gutter .text p {
        height: 72px;
        margin-bottom: 30px; } }

.partneri_pages {
  padding-top: 100px; }
  .partneri_pages .header h4 {
    text-align: center; }
    .partneri_pages .header h4::after {
      content: '';
      width: 50px;
      height: 4px;
      background-color: #1D538C;
      display: block;
      position: relative;
      left: 47.5%;
      top: 14px;
      margin-bottom: 20px; }
  .partneri_pages .header p {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    padding-top: 10px; }
  .partneri_pages .content {
    padding-top: 100px; }
    .partneri_pages .content .meindl {
      padding-top: 80px; }
    .partneri_pages .content h2 {
      padding-top: 27px;
      font-size: 34px;
      line-height: 28px;
      padding-bottom: 32px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .partneri_pages .header h4 {
    font-size: 26px;
    line-height: 38px; }
    .partneri_pages .header h4::after {
      left: 44.7%; }
  .partneri_pages .header p {
    font-size: 16px;
    line-height: 26px; }
  .partneri_pages .content {
    padding-top: 50px; }
    .partneri_pages .content h2 {
      font-size: 24px;
      padding-bottom: 20px; }
    .partneri_pages .content p {
      font-size: 16px; }
    .partneri_pages .content .meindl {
      padding: unset; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .partneri_pages .content .meindl {
    padding-top: 0; } }

.partner-image img {
  width: 100%;
  object-fit: contain;
  object-position: center;
  height: 100%;
  padding: 15px; }

.partneri h4 {
  padding-top: 20px; }
  .partneri h4::after {
    content: '';
    width: 50px;
    height: 4px;
    background-color: #1D538C;
    display: block;
    margin-bottom: 19px;
    margin-top: 12px; }
  .partneri h4 p {
    font-size: 20px;
    line-height: 28px;
    font-family: 'Barlow', sans-serif;
    color: #222222; }

.partneri .slika {
  border: 1px solid #E2E2E2;
  width: 255px;
  height: 140px;
  text-align: center;
  margin-bottom: 20px;
  background-color: white; }

.partneri .btn {
  background-color: transparent;
  color: #333333;
  border: 2px solid #333333;
  height: 46px;
  font-size: 18px;
  line-height: 24px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .partneri .slika {
    width: 100%; }
  .partneri h4 {
    font-size: 26px;
    line-height: 38px; }
  .partneri p {
    font-size: 16px;
    line-height: 28px; }
  .partneri a .btn {
    margin-top: 20px;
    margin-bottom: 30px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .partneri .btn {
    margin-bottom: 40px; }
  .partneri .bla .slika {
    width: unset; } }

.head h4 {
  text-align: center;
  padding-top: 100px; }
  .head h4::after {
    content: '';
    width: 50px;
    height: 4px;
    display: block;
    background-color: #1D538C;
    position: relative;
    left: 48.5%;
    top: 15px; }

.head p {
  text-align: center;
  font-size: 20px;
  padding-top: 20px; }

.kontakt_page {
  background-color: #F6F6F6;
  margin-bottom: 40px;
  margin-top: 48px; }
  .kontakt_page h3 {
    font-size: 18px;
    font-family: 'Barlow', sans-serif;
    line-height: 22px;
    padding-top: 50px;
    padding-bottom: 22px; }
  .kontakt_page input {
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    height: 44px; }
  .kontakt_page input::placeholder {
    color: #232628; }
  .kontakt_page textarea {
    height: 200px !important;
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    resize: none; }
  .kontakt_page textarea::placeholder {
    color: #232628; }
  .kontakt_page label {
    font-size: 12px;
    line-height: 14px;
    color: #9F9F9F;
    padding-top: 10px;
    font-family: 'Barlow', sans-serif; }
  .kontakt_page .btn {
    height: 46px;
    width: 160px;
    background-color: #0064A1;
    color: white;
    margin-top: 24px;
    margin-bottom: 50px; }
  .kontakt_page .kontaktInfo {
    background-color: #1D538C; }
    .kontakt_page .kontaktInfo .content {
      color: white;
      padding: 53.3px 0 0 39px; }
      .kontakt_page .kontaktInfo .content h6 {
        font-size: 22px;
        font-family: 'Barlow', sans-serif;
        line-height: 26px; }
      .kontakt_page .kontaktInfo .content p {
        font-size: 16px;
        line-height: 18px;
        padding-bottom: 43px; }
      .kontakt_page .kontaktInfo .content h2 {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 44px; }
      .kontakt_page .kontaktInfo .content h3 {
        padding-top: 23px;
        font-size: 18px;
        line-height: 22px;
        font-family: 'Barlow', sans-serif; }
        .kontakt_page .kontaktInfo .content h3 .isvg {
          padding-left: 20px; }
          .kontakt_page .kontaktInfo .content h3 .isvg svg {
            fill: white !important;
            position: relative;
            bottom: 6px; }
      .kontakt_page .kontaktInfo .content .nav-item {
        list-style-type: none;
        padding-bottom: 20px; }
        .kontakt_page .kontaktInfo .content .nav-item a {
          color: white;
          text-decoration: none;
          font-size: 18px;
          line-height: 20px; }
        .kontakt_page .kontaktInfo .content .nav-item .nav-link {
          padding: unset;
          color: white;
          text-decoration: none; }
      .kontakt_page .kontaktInfo .content .kontakt {
        display: inline;
        padding-right: 25px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .head h4 {
    padding-top: 60px;
    font-size: 26px;
    line-height: 38px; }
    .head h4::after {
      left: 41.5%;
      top: 5px; }
  .head p {
    font-size: 18px; }
  .kontakt_page .kontaktInfo .content {
    padding: 32.3px 0 0 25px; }
    .kontakt_page .kontaktInfo .content .kontakt {
      padding-right: 10px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .kontakt_page .kontaktInfo .content {
    padding: 30px 0 0 10px; }
    .kontakt_page .kontaktInfo .content .nav-item .kontakt {
      padding-right: 10px; }
    .kontakt_page .kontaktInfo .content .nav-item a {
      font-size: 16px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .kontakt_page .kontaktInfo .content {
    padding-left: 36px; } }

.lider {
  padding-top: 100px; }
  .lider h4 {
    padding-top: 20px;
    padding-bottom: 58px; }
  .lider .lider2 {
    padding-top: 100px; }
    .lider .lider2 .slika {
      padding-bottom: 20px; }
    .lider .lider2 p {
      font-size: 18px;
      line-height: 28px;
      font-family: 'Barlow', sans-serif; }

.partneri_about {
  background-color: #F6F6F6; }
  .partneri_about .partneri h4 {
    padding-top: 60px; }
  .partneri_about .partneri .bla {
    padding-top: 40px;
    padding-bottom: 20px; }

@media screen and (max-width: 768px) and (min-width: 320px) {
  .lider h4 {
    font-size: 26px;
    line-height: 38px;
    padding-bottom: 25px; }
  .lider p {
    font-size: 16px; }
  .lider .lider2 {
    padding-top: 50px; }
    .lider .lider2 .slika {
      width: 100%; }
    .lider .lider2 p {
      font-size: 16px;
      margin-top: 30px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lider h4 {
    padding-bottom: 22px; }
  .lider img {
    width: 100%; }
  .lider .lider2 .slika {
    padding-bottom: 30px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .lider h4 {
    padding-top: 0;
    padding-bottom: 10px; }
  .lider .lider2 {
    padding-top: 20px; } }

.gall {
  padding-top: 64px;
  padding-bottom: 80px; }
  .gall .pictures {
    margin-top: 20px;
    position: relative; }
    .gall .pictures img {
      display: block;
      margin: auto;
      position: relative;
      top: 35%; }
  .gall .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #8492da; }
  .gall .pictures:hover .overlay {
    opacity: 0.8; }
    .gall .pictures:hover .overlay img {
      margin: auto;
      display: block;
      position: relative;
      top: 35%; }
  .gall .slikee img {
    height: 200px;
    width: 100%; }
  .gall .rowDivider {
    margin-top: 30px; }

/*lightbox gallery*/
.lightbox {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: #333333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1213113212; }
  .lightbox .container {
    background: #333333; }
  .lightbox img {
    height: 100vh;
    object-fit: contain;
    margin-top: 40px; }
  .lightbox .fa-times {
    color: white;
    font-size: 42px !important;
    position: absolute;
    top: 60px;
    right: 70px;
    z-index: 2; }
    .lightbox .fa-times:hover {
      cursor: pointer; }
  .lightbox .fa-angle-left {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    left: 40px; }
    .lightbox .fa-angle-left:hover {
      cursor: pointer; }
  .lightbox .fa-angle-right {
    color: white;
    font-size: 50px;
    position: fixed;
    top: 48%;
    right: 40px; }
    .lightbox .fa-angle-right:hover {
      cursor: pointer; }

/*end lightbox*/
body {
  overflow-x: hidden !important;
  padding: 40px 40px 40px 40px; }
  body .btn {
    margin-top: 32px;
    text-transform: uppercase;
    float: left;
    color: white;
    width: 160px;
    height: 54px;
    border-radius: 204px;
    background-color: #333333;
    font-family: 'Barlow', sans-serif;
    font-weight: 600; }
  body h4 {
    font-size: 36px;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    line-height: 50px; }
  body p {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    line-height: 25px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  body {
    overflow-x: hidden !important;
    padding: 10px 10px 10px 10px !important; }
  .no-pa {
    padding: 0 !important;
    width: 100% !important; } }

.no-ma {
  margin: 0 !important; }

.header_navigaton {
  background-image: url("./images/background_slider.png");
  height: 300px; }
  .header_navigaton .container .asa {
    position: relative; }
    .header_navigaton .container .asa h2 {
      top: 125px;
      position: absolute;
      font-size: 50px;
      text-transform: uppercase;
      padding-left: 10px; }
    .header_navigaton .container .asa .position {
      position: absolute;
      right: 15px;
      top: 145px; }
      .header_navigaton .container .asa .position h3 {
        text-transform: uppercase;
        font-size: 18px; }
        .header_navigaton .container .asa .position h3 a {
          text-decoration: none;
          color: #212529; }

.section-1 {
  padding-top: 35px; }
  .section-1 .slika {
    padding-bottom: 20px; }
  .section-1 h4::after {
    content: '';
    display: block;
    height: 4px;
    width: 50px;
    background: #1D538C; }
  .section-1 p {
    padding-top: 29px;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Barlow', sans-serif; }
  .section-1 .btn {
    background-color: transparent;
    color: #333333;
    border: 2px solid #333333; }
  .section-1 .text {
    padding-left: 15px; }

.section-3 {
  background-image: url("./images/rectangle.png");
  margin-top: 40px;
  color: white; }
  .section-3 h4::after {
    background-color: white; }
  .section-3 .content {
    padding-top: 60px;
    padding-bottom: 70px; }
    .section-3 .content .text {
      padding-bottom: 68px; }
      .section-3 .content .text h4 {
        font-size: 36px;
        line-height: 40px;
        font-family: 'Barlow', sans-serif;
        font-weight: 600;
        color: white !important; }
        .section-3 .content .text h4::before {
          content: '';
          width: 4px;
          height: 50px;
          background-color: white !important;
          display: block;
          position: absolute;
          right: 0; }
      .section-3 .content .text p {
        padding-left: 60px;
        font-size: 20px;
        line-height: 28px;
        font-family: 'Barlow', sans-serif; }
    .section-3 .content .selected {
      padding-bottom: 30px;
      position: relative; }
      .section-3 .content .selected .aboutSelected {
        width: 100%;
        position: absolute;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.45) 51%, rgba(0, 0, 0, 0) 100%);
        top: 224px;
        bottom: 30px; }
        .section-3 .content .selected .aboutSelected h5 {
          font-family: 'Barlow', sans-serif;
          font-weight: 500;
          font-size: 46px;
          line-height: 48px;
          padding: 18px 0 3px 26px;
          margin: 0; }
          .section-3 .content .selected .aboutSelected h5 span {
            font-weight: bold;
            display: block; }
        .section-3 .content .selected .aboutSelected p {
          margin: 0;
          font-size: 18px;
          font-family: 'Barlow', sans-serif;
          padding: 0 0 0 26px; }
        .section-3 .content .selected .aboutSelected .btn {
          margin-top: 41px;
          margin-left: 26px;
          border-radius: 0;
          border: 1px solid white;
          background-color: transparent; }
          .section-3 .content .selected .aboutSelected .btn:hover {
            background-color: #fff;
            color: black; }
          .section-3 .content .selected .aboutSelected .btn h6 {
            font-size: 16px;
            font-family: 'Barlow', sans-serif;
            font-weight: 500;
            line-height: 19px;
            margin: 0; }
            .section-3 .content .selected .aboutSelected .btn h6::after {
              content: '';
              border: solid #ffffff;
              border-width: 0 2px 2px 0;
              display: inline-block;
              padding: 5px;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              position: relative;
              left: 4.3px; }

.novosti {
  margin-top: 70px;
  margin-bottom: 49px; }
  .novosti .content .text h4 {
    font-size: 36px;
    line-height: 50px;
    color: #222222;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    text-transform: uppercase; }
    .novosti .content .text h4::before {
      content: '';
      width: 4px;
      height: 50px;
      background-color: #1D538C;
      display: block;
      position: absolute;
      right: 0; }
  .novosti .content .text p {
    padding-top: 13px;
    font-size: 20px;
    line-height: 24px;
    font-family: 'Barlow', sans-serif;
    padding-left: 61px; }
  .novosti .content .text h5 {
    text-transform: uppercase;
    padding-top: 17px;
    position: absolute;
    right: 0;
    font-size: 18px;
    line-height: 24px;
    color: #1D538C; }
    .novosti .content .text h5::before {
      content: '';
      border: solid #333333;
      border-width: 0 1.43px 1.43px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-right: 11.9px;
      margin-bottom: 4px; }

.foto_galerija {
  position: relative;
  background-color: #F6F6F6;
  text-align: center; }
  .foto_galerija h4 {
    padding-top: 104px; }
    .foto_galerija h4::after {
      position: relative;
      left: 47.3%;
      content: '';
      display: block;
      height: 4px;
      width: 50px;
      background: #1D538C;
      margin-bottom: 56px;
      top: 14px; }
    .foto_galerija h4::before {
      content: url("./images/SVG/patike.svg");
      position: absolute;
      left: 48.4%;
      top: 60px; }
  .foto_galerija .no-gutter > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }
  .foto_galerija .asa {
    display: flex;
    margin-top: -175px;
    padding-left: -15px;
    padding-right: -15px; }
  .foto_galerija a .btn {
    margin-top: 60px;
    margin-bottom: 60px;
    width: 240px;
    height: 46px;
    background-color: #1D538C;
    color: white;
    text-transform: uppercase; }
  .foto_galerija .pictures {
    position: relative; }
    .foto_galerija .pictures img {
      display: block;
      margin: auto;
      position: relative;
      top: 35%; }
    .foto_galerija .pictures .slika {
      margin-bottom: 30px; }
    .foto_galerija .pictures .slika1 {
      margin-top: 13px; }
  .foto_galerija .overlay {
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #8492da; }
  .foto_galerija .pictures:hover .overlay {
    opacity: 0.8; }
    .foto_galerija .pictures:hover .overlay img {
      margin: auto;
      display: block;
      position: relative;
      top: 35%; }

/*map*/
.partneri_home {
  padding-top: 80px; }

.mapa {
  padding-top: 40px;
  margin-bottom: 40px; }
  .mapa .map {
    height: 550px; }

@media screen and (max-width: 767px) and (min-width: 320px) {
  .header_navigaton {
    height: 180px; }
    .header_navigaton .container .asa h2 {
      font-size: 35px;
      padding: unset;
      top: -10px; }
    .header_navigaton .container .asa .position {
      display: none; }
  .section-1 .slika {
    width: 100%; }
  .section-1 .text h4 {
    padding-top: 15px;
    font-size: 26px;
    line-height: 38px; }
    .section-1 .text h4::after {
      margin-top: 5px; }
  .section-1 .text p {
    padding-top: 15px;
    font-size: 16px;
    line-height: 27px; }
  .section-1 .btn {
    margin-top: 15px; }
  .section-3 .content {
    padding-top: 30px;
    padding-bottom: 30px; }
    .section-3 .content .text {
      padding-bottom: 20px; }
      .section-3 .content .text h4 {
        font-size: 26px;
        line-height: 38px; }
        .section-3 .content .text h4::before {
          right: unset;
          left: 160px; }
      .section-3 .content .text p {
        padding-top: 20px;
        padding-left: unset;
        font-size: 16px;
        line-height: 27px; }
    .section-3 .content .selected .aboutSelected h5 {
      font-size: 36px;
      line-height: 43px; }
    .section-3 .content .selected .aboutSelected p {
      font-size: 16px; }
    .section-3 .content .selected .aboutSelected .btn {
      margin-top: 20px; }
      .section-3 .content .selected .aboutSelected .btn h6 {
        margin: 0; }
  /*novosti*/
  .novosti .content .text h4 {
    font-size: 26px;
    line-height: 38px; }
    .novosti .content .text h4::before {
      right: unset;
      left: 160px; }
  .novosti .content .text p {
    padding-top: unset;
    padding-left: 0;
    font-size: 16px;
    line-height: 27px; }
  .novosti .content .text h5 {
    right: 10px; }
  .novosti .boxNews {
    padding-top: 70px; }
  /*end novosti*/
  .foto_galerija .slika {
    width: 300px; }
  .foto_galerija .slika1 {
    width: 300px; }
  .foto_galerija h4 {
    padding-top: 75px; }
    .foto_galerija h4:before {
      top: 30px;
      left: 46%; }
    .foto_galerija h4::after {
      left: 45%; }
  .foto_galerija .asa {
    margin-top: unset;
    display: block; }
    .foto_galerija .asa img {
      padding-left: 10px;
      padding-right: 10px; }
  .foto_galerija a .btn {
    margin-bottom: 30px; }
  .partneri_home {
    padding-top: 40px; } }

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .header_navigaton {
    height: 200px; }
    .header_navigaton .container .asa h2 {
      top: 100px;
      padding: unset;
      font-size: 45px; }
    .header_navigaton .container .asa .position {
      top: 130px; }
  .section-1 {
    padding-top: 0; }
    .section-1 .slika {
      padding-bottom: 30px; }
  .foto_galerija .no-gutter > [class*="col-"] {
    padding-right: unset;
    padding-left: 15px; }
  .foto_galerija .asa {
    margin-top: 0; }
    .foto_galerija .asa .slika1 {
      padding-top: unset; }
  .foto_galerija a .btn {
    margin-top: unset; }
  .section-3 .content {
    padding-top: 0;
    padding-bottom: 15px; }
    .section-3 .content .text {
      padding-top: 35px;
      padding-bottom: 35px; }
    .section-3 .content .selected {
      padding-bottom: 20px; }
      .section-3 .content .selected .aboutSelected {
        top: unset;
        bottom: 20px; }
        .section-3 .content .selected .aboutSelected h5 {
          font-size: 20px;
          line-height: 30px;
          padding-left: 15px; }
        .section-3 .content .selected .aboutSelected p {
          padding-left: 15px; }
        .section-3 .content .selected .aboutSelected a .btn {
          margin-left: 15px;
          margin-top: 15px;
          margin-bottom: 10px; }
  .novosti .content .text {
    padding-bottom: 70px; }
    .novosti .content .text p {
      text-align: end; }
    .novosti .content .text h5 {
      right: 15px; } }

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .header_navigaton .container .asa {
    position: relative; }
    .header_navigaton .container .asa .h2 {
      padding: unset;
      margin-left: -10px !important; }
    .header_navigaton .container .asa .position {
      right: 100px; }
  .header_navigaton h2 {
    bottom: 20px;
    left: 120px; }
  .section-1 {
    top: -10px; }
    .section-1 .slika {
      padding-bottom: 37px; }
    .section-1 .no-pa-left {
      padding-left: 0; }
    .section-1 h4 {
      line-height: 40px; }
    .section-1 p {
      padding-top: 0; }
    .section-1 .btn {
      margin-top: 0; }
    .section-1 .text {
      position: relative; }
  .foto_galerija .slika1 {
    padding-top: 13px !important; }
  .foto_galerija .asa {
    margin-top: -130px; }
  /*.section-3 {
                    .content {
                        .selected {
                            .aboutSelected {
                                width: 100%;
                            }
                        }
                    }
                }*/
  .novosti .content .text {
    padding-bottom: 70px; } }

.required label {
  color: red; }

.required input, .required textarea {
  border: 1px solid red; }

.title-policy {
  padding-top: 50px;
  text-align: center;
  position: relative;
  margin-bottom: 26px; }
  .title-policy::after {
    content: '';
    width: 50px;
    height: 4px;
    background-color: #1D538C;
    display: block;
    margin-bottom: 19px;
    margin-top: 12px;
    position: absolute;
    left: 50%;
    margin-left: -25px; }
